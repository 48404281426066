<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mt-1"
      >
        <b>{{ $t('contractual-conditions') }}</b>
        <hr>
      </b-col>
      <template v-if="option !== sectionCoachingStaff">
        <b-col
          cols="12"
          sm="12"
          md="3"
          lg="3"
        >
          <b-form-radio-group
            v-model="member.contractual_condition.type_contractual_conditions"
            :options="contractualConditionsOptions"
            class="mb-2"
            @change="() => saveDataTemporarily()"
          />
        </b-col>
        <b-col
          cols="12"
          sm="12"
          md="3"
          lg="3"
        >
          <b-form-group>
            <label>{{ $t('especify') }} <span class="text-danger">*</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required|max:254|alphaNumSpacesRegex"
              name="description"
              :immediate="isInmediate"
            >
              <b-form-input
                v-model="member.contractual_condition.descrition_contractual_conditions"
                type="text"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('generic.description')"
                @change="() => saveDataTemporarily()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </template>
    </b-row>
    <floating-profile
      :name="member.name + ' ' + member.first_name"
      :avatar="member.avatar_file.url_thumb"
    />
    <validation-observer ref="formconditionsConditions">
      <b-form>
        <b-row>
          <!-- Operacion -->
          <template
            v-if="member.contractual_condition.type_contractual_conditions === 'TRANSFER'"
          >
            <b-col
              cols="12"
              class="mt-1"
            >
              <b>{{ $t('transfer') }}</b>
            </b-col>
            <b-col
              cols="12"
              class="mt-1"
            >
              <b-form-radio-group
                v-model="member.contractual_condition.transfer.operations"
                :options="operationOptions"
                class="mb-2"
                @change="() => saveDataTemporarily()"
              />
              <hr>
            </b-col>
            <!-- Numero de TMS -->
            <template
              v-if="member.contractual_condition.transfer.operations === 'INTERNATIONAL'"
            >
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                xl="3"
              >
                <b-form-group>
                  <label>{{ $t('number-tms') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required|max:254|alphaNumSpacesRegex"
                    name="numberTMS"
                    :immediate="isInmediate"
                  >
                    <b-form-input
                      v-model="member.contractual_condition.transfer.tms_number"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('write-tms-number')"
                      @change="() => saveDataTemporarily()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
            <b-col
              cols="12"
              sm="12"
              md="6"
              lg="3"
              xl="3"
            >
              <b-form-group>
                <label>{{ $t('type-of-operation') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  name="typeOperation"
                  :immediate="isInmediate"
                >
                  <v-select
                    v-model="member.contractual_condition.transfer.type_operation"
                    label="name"
                    :options="typeOperations"
                    :placeholder="$t('select-type-of-operation')"
                    @input="() => saveDataTemporarily()"
                  >
                    <!-- @input="() => $emit('handle-data-temporarily')" -->
                    <div slot="no-options">
                      {{ $t('generic.noRecordsFound') }}
                    </div>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Date type operation -->
            <template v-if="typeOperation === 'TEMPORARY'">
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                xl="3"
              >
                <b-form-group>
                  <label>{{ $t('generic.initDate') }}<span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="transferOperationInitDate"
                    :immediate="isInmediate"
                  >
                    <b-form-datepicker
                      v-model="member.contractual_condition.transfer.init_date_operation"
                      :locale="$i18n.locale"
                      class="form-control"
                      :placeholder="$t('select-date')"
                      :state="errors.length > 0 ? false : null"
                      @change="() => saveDataTemporarily()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Fecha final -->
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                xl="3"
              >
                <b-form-group>
                  <label>{{ $t('generic.endDate') }}<span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="transferOperationEndDate"
                    :immediate="isInmediate"
                  >
                    <b-form-datepicker
                      v-model="member.contractual_condition.transfer.end_date_operation"
                      :locale="$i18n.locale"
                      :min="member.contractual_condition.transfer.init_date_operation"
                      class="form-control"
                      :placeholder="$t('select-date')"
                      :state="errors.length > 0 ? false : null"
                      :date-info-fn="() => saveDataTemporarily()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
            <!-- End date -->
            <b-col
              cols="12"
              sm="12"
              md="6"
              lg="3"
              xl="3"
            >
              <b-form-group>
                <label>{{ $t('club-cedente') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|max:254|alphaNumSpacesRegex"
                  name="transferNameClub"
                  :immediate="isInmediate"
                >
                  <b-form-input
                    v-model="member.contractual_condition.transfer.ceding_club"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('corporate.clubName')"
                    @change="() => saveDataTemporarily()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              md="6"
              lg="3"
              xl="3"
            >
              <b-form-group>
                <label>{{ $t('percentage-of-transfer-of-economic-rights') }} <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  rules="required|between:1,100"
                  name="transferPercentage"
                  :immediate="isInmediate"
                >
                  <b-form-input
                    v-model="member.contractual_condition.transfer.percentage_transfer_economic_rights"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('add-porcentage')"
                    @change="() => saveDataTemporarily()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <div class="w-100" />
            <!-- Condiciones de transferencia -->
            <template v-if="member.contractual_condition.transfer.percentage_transfer_economic_rights < 100">
              <b-col
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >
                <b-form-group>
                  <label>{{ $t('transfer-conditions') }} <span class="text-danger">*</span></label>
                  <rich-text-edit
                    :model-value.sync="member.contractual_condition.transfer.transfer_conditions"
                    :placeholder="$t('escribe-transfer-conditions')"
                  />
                </b-form-group>
              </b-col>
            </template>

            <div class="w-100" />

            <!-- Acuerdo o contrato -->
            <b-col
              cols="12"
              class="mt-1"
            >
              <b>{{ $t('agreement-or-contract') }}</b>
            </b-col>
            <div class="w-100" />
            <!-- Operacion -->
            <b-col
              cols="12"
            >
              <!-- <label>¿Acuerdo o contrato? <span class="text-danger">*</span></label> -->
              <b-form-radio-group
                v-model="member.contractual_condition.transfer.contract_agreement"
                :options="controlOptions"
                class="mb-2 mt-2"
                @change="() => saveDataTemporarily()"
              />
              <hr>
            </b-col>
            <div class="w-100" />

            <template v-if="member.contractual_condition.transfer.contract_agreement === 'YES'">
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                xl="3"
              >
                <b-form-group>
                  <label>{{ $t('representative-or-agent') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="transferRepresentative"
                    :immediate="isInmediate"
                  >
                    <!-- key not repit -->
                    <v-select
                      v-model="member.contractual_condition.transfer.assign_representative_key"
                      label="name"
                      :options="representativesList"
                      :reduce="representative => representative.key"
                      :placeholder="$t('select-legal-representative')"
                      @input="() => saveDataTemporarily()"
                    >
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <!-- {{representativesList}} -->
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Fecha de firma -->
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                xl="3"
              >
                <b-form-group>
                  <label>{{ $t('signature-date') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="transferContractSignatureDate"
                    :immediate="isInmediate"
                  >
                    <b-form-datepicker
                      v-model="member.contractual_condition.transfer.signature_date"
                      :locale="$i18n.locale"
                      class="form-control"
                      :placeholder="$t('member.form.placeholder.selectDate')"
                      :state="errors.length > 0 ? false : null"
                      :date-info-fn="() => saveDataTemporarily()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Fechas -->
              <div class="w-100" />
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                xl="6"
                class="mt-1"
              >
                <b>{{ $t('generic.validity') }}</b>
                <hr>
              </b-col>
              <div class="w-100" />
              <!-- Fecha inicial -->
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                xl="3"
              >
                <b-form-group>
                  <label>{{ $t('generic.initDate') }}<span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="transferContractInitDate"
                    :immediate="isInmediate"
                  >
                    <b-form-datepicker
                      v-model="member.contractual_condition.transfer.init_date"
                      :locale="$i18n.locale"
                      class="form-control"
                      :placeholder="$t('select-date')"
                      :state="errors.length > 0 ? false : null"
                      :date-info-fn="() => saveDataTemporarily()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Fecha final -->
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                xl="3"
              >
                <b-form-group>
                  <label>{{ $t('generic.endDate') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="transferContractEndDate"
                    :immediate="isInmediate"
                  >
                    <b-form-datepicker
                      v-model="member.contractual_condition.transfer.end_date"
                      :locale="$i18n.locale"
                      :min="member.contractual_condition.transfer.init_date"
                      class="form-control"
                      :placeholder="$t('select-date')"
                      :state="errors.length > 0 ? false : null"
                      :date-info-fn="() => saveDataTemporarily()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Contraprestacion -->
              <b-col
                cols="12"
                class="mt-1"
              >
                <b>{{ $t('consideration') }}</b>
                <hr>
              </b-col>
              <!-- TODO -->

              <!-- Cantidad fija -->
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                xl="3"
              >
                <b-form-group>
                  <b-form-checkbox
                    id="transfer-fixed-quantity"
                    v-model="member.contractual_condition.transfer.against_provision.has_fixed_quantity"
                    :value="true"
                  >
                    <label for="transfer-fixed-quantity">{{ $t('fixed-quantity') }}</label>
                  </b-form-checkbox>
                  <validation-provider
                    v-if="member.contractual_condition.transfer.against_provision.has_fixed_quantity"
                    #default="{ errors }"
                    rules="integer|max:16"
                    name="transferFixedQuantity"
                  >
                    <b-input-group
                      prepend="$"
                      class="input-group-merge"
                    >
                      <b-form-input
                        v-model="member.contractual_condition.transfer.against_provision.fixed_quantity"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('placeholder-quantity')"
                        @change="() => saveDataTemporarily()"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Catidad variable -->
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                xl="3"
              >
                <b-form-group>
                  <b-form-checkbox
                    id="transfer-variable-quantity"
                    v-model="member.contractual_condition.transfer.against_provision.has_variable_quantity"
                    :value="true"
                  >
                    <label for="transfer-variable-quantity">{{ $t('variable-quantity') }}</label>
                  </b-form-checkbox>
                  <validation-provider
                    v-if="member.contractual_condition.transfer.against_provision.has_variable_quantity"
                    #default="{ errors }"
                    rules="integer|max:16"
                    name="transferVariableQuantity"
                  >
                    <b-input-group
                      prepend="$"
                      class="input-group-merge"
                    >
                      <b-form-input
                        v-model="member.contractual_condition.transfer.against_provision.variable_quantity"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('placeholder-quantity')"
                        @change="() => saveDataTemporarily()"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Describir forma de pago -->
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                xl="3"
              >
                <b-form-group>
                  <label>{{ $t('method-payment') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required|max:254|alphaNumSpacesRegex"
                    name="transferMethodPayment"
                    :immediate="isInmediate"
                  >
                    <b-form-input
                      v-model="member.contractual_condition.transfer.method_payment"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('describe-payment-method')"
                      @change="() => saveDataTemporarily()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- periodicidad -->
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                xl="3"
              >
                <b-form-group>
                  <label>{{ $t('periodicity') }} <span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="transferPeriodicity"
                    :immediate="isInmediate"
                  >
                    <v-select
                      id="club-country-id"
                      v-model="member.contractual_condition.transfer.payment_period"
                      label="name"
                      :options="typePaymentperiods"
                      :placeholder="$t('select-periodicity')"
                      @input="() => saveDataTemporarily()"
                    >
                      <div slot="no-options">
                        {{ $t('generic.noRecordsFound') }}
                      </div>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <template v-if="transferPaymentPeriodkey === 'OTHER'">
                <b-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="3"
                  xl="3"
                >
                  <b-form-group>
                    <label>{{ $t('other-periodicity') }} <span class="text-danger">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required|max:254|alphaNumSpacesRegex"
                      name="transferPeriodicityOther"
                      :immediate="isInmediate"
                    >
                      <b-form-input
                        v-model="member.contractual_condition.transfer.payment_period_other"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('describe-periodicity')"
                        @change="() => saveDataTemporarily()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>

              <!-- Cantidad de penalizacion -->
              <b-col
                cols="12"
                sm="12"
                md="6"
                lg="3"
                xl="3"
              >
                <b-form-group>
                  <label>{{ $t('amount-of-penalty') }}<span class="text-danger">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="integer|max:16"
                    name="transferPenaltyAmount"
                    :immediate="isInmediate"
                  >
                    <b-input-group
                      prepend="$"
                      class="input-group-merge"
                    >
                      <b-form-input
                        v-model="member.contractual_condition.transfer.penalty_amount"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('placeholder-quantity')"
                        @change="() => saveDataTemporarily()"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Domicilio de notificacion -->
              <b-col
                cols="12"
              >
                <notification-address-transfer-form
                  :member.sync="member"
                  :countries="countries"
                  :type-jurisdictions="typeJurisdictions"
                  :option="option"
                />
              </b-col>

            </template>
            <!-- End Contract or acuerdo -->

            <b-col
              cols="12"
            >
              <!-- Agent -->
              <agent-transfer-form
                :member.sync="member"
                :control-options="controlOptions"
                :type-paymentperiods="typePaymentperiods"
                :representatives-list="representativesList"
                :option="option"
              />
              <!-- End Agente -->
            </b-col>

          </template>
          <!-- End Transfereancia -->

        </b-row>
        <general-form
          :member.sync="member"
          :option="option"
          :section-coaching-staff="sectionCoachingStaff"
          :type-contracts="typeContracts"
          :representatives-list="representativesList"
        />
        <!-- :save-data-temporarily.sync="saveDataTemporarily" -->
        <increments-form
          :member.sync="member"
          :option="option"
          :color-primary-btn="colorPrimaryBtn"
        />
        <general-form-two
          :type-paymentperiods="typePaymentperiods"
          :member="member"
          :option="option"
          :color-primary-btn="colorPrimaryBtn"
        />

        <notification-address-general-form
          :member.sync="member"
          :countries="countries"
          :type-jurisdictions="typeJurisdictions"
          :option="option"
        />

        <!-- Agent -->
        <template v-if="option !== sectionCoachingStaff">
          <agent-general-form
            :member="member"
            :control-options="controlOptions"
            :type-paymentperiods="typePaymentperiods"
            :option="option"
          />
        </template>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import clubsService from '@/services/clubsService'

import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { isObject, hasKeyObject } from '@core/utils/utils'
import { validatorFileSize, validatorFileType } from '@core/utils/validations/validators'
import vSelect from 'vue-select'
import RichTextEdit from '@/views/member/components/RichTextEdit.vue'
import FloatingProfile from '@/views/member/components/FloatingProfile.vue'
// import { useWindowScroll } from '@vueuse/core'
// import UploadImage from '../../partials/UploadImage.vue'
import NotificationAddressGeneralForm from './general/NotificationAddressGeneralForm.vue'
import NotificationAddressTransferForm from './transfer/NotificationAddressTransferForm.vue'
import GeneralForm from './GeneralForm.vue'
import IncrementsForm from './IncrementsForm.vue'
import GeneralFormTwo from './general/GeneralFormTwo.vue'
import AgentGeneralForm from './general/AgentGeneralForm.vue'
import AgentTransferForm from './transfer/AgentTransferForm.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    // UploadImage,
    GeneralForm,
    IncrementsForm,
    NotificationAddressGeneralForm,
    NotificationAddressTransferForm,
    GeneralFormTwo,
    AgentGeneralForm,
    AgentTransferForm,
    RichTextEdit,
    FloatingProfile,
  },
  directives: {
    Ripple,
  },
  props: {
    countries: {
      type: Array,
      default: null,
    },
    option: {
      type: String,
      default: null,
    },
    member: {
      type: Object,
      default: null,
    },
    colorPrimaryBtn: {
      type: Object,
      default: null,
    },
    sectionCoachingStaff: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      y: null,
      selected: false,
      required,
      email,
      typeOperation: null,
      transferPaymentPeriodkey: null,
      // Static
      operationOptions: [
        {
          text: this.$t('domestic'),
          value: 'DOMESTIC',
        },
        {
          text: this.$t('international'),
          value: 'INTERNATIONAL',
        },
      ],
      contractualConditionsOptions: [
        {
          text: this.$t('transfer'),
          value: 'TRANSFER',
        },
        {
          text: this.$t('free-of-contract'),
          value: 'FREE_CONTRACT',
        },
      ],
      controlOptions: [{
        text: this.$t('generic.yes'),
        value: 'YES',
      }, {
        text: this.$t('generic.not'),
        value: 'NO',
      }],
      filters: {
        page: 1,
        perPage: 10,
        totalPowers: 0,
        clubId: null,
      },
      file_document_transactions_agent: [],
      file_document_general_agent: [],
      representativesList: [],
    }
  },
  computed: {
    ...mapGetters({
      clubInfo: 'clubInfo',
      genderHash: 'memberStore/selectGenderHash',
      typeMemberStorage: 'memberStore/typeMemberStorage',

      typeOperations: 'catalogs/typeOperations',
      typePaymentperiods: 'catalogs/typePaymentperiods',
      typeJurisdictions: 'catalogs/typeJurisdictions',
      typeContracts: 'catalogs/typeContracts',
    }),
    isInmediate() {
      return this.member.is_complete_data === true
    },
  },
  watch: {
    'member.contractual_condition.transfer.type_operation': function transferTypeOperation(operation) {
      if (isObject(operation)) {
        this.member.contractual_condition.transfer.type_operation_hash = operation.id
        this.typeOperation = operation.key
      } else {
        this.typeOperation = null
        this.member.contractual_condition.transfer.init_date_operation = null
        this.member.contractual_condition.transfer.end_date_operation = null
      }
    },
    'member.contractual_condition.transfer.payment_period': function transferPaymentPeriodOption(period) {
      if (isObject(period)) {
        this.member.contractual_condition.transfer.payment_period_hash = period.id
        this.transferPaymentPeriodkey = period.key
      } else {
        this.member.contractual_condition.transfer.payment_period_other = null
        this.transferPaymentPeriodkey = null
      }
    },
    'member.contractual_condition.transfer.transfer_conditions': function memberConditionsTransferTransferConditions() {
      this.saveDataTemporarily()
    },
  },
  mounted() {
    this.fetchRepresentatives()
    this.fetchTypeOperations()
    this.fetchTypePaymentperiods()
    this.fetchTypeJustifications()
    this.fetchTypeContracts()

    // Only edit
    if (isObject(this.member.contractual_condition.transfer.payment_period) && hasKeyObject(this.member.contractual_condition.transfer.payment_period, 'key')) {
      this.transferPaymentPeriodkey = this.member.contractual_condition.transfer.payment_period.key
    }
    if (isObject(this.member.contractual_condition.transfer.type_operation) && hasKeyObject(this.member.contractual_condition.transfer.type_operation, 'key')) {
      this.typeOperation = this.member.contractual_condition.transfer.type_operation.key
    }
  },
  methods: {
    validatorFileSize,
    validatorFileType,
    saveDataTemporarily() {
      localStorage.setItem(this.typeMemberStorage, JSON.stringify(this.member))
    },
    ...mapActions({

      // Catalogs
      fetchTypeOperations: 'catalogs/fetchTypeOperations',
      fetchTypePaymentperiods: 'catalogs/fetchTypePaymentperiods',
      fetchTypeJustifications: 'catalogs/fetchTypeJustifications',
      fetchTypeContracts: 'catalogs/fetchTypeContracts',
    }),
    async fetchRepresentatives() {
      await clubsService.getRepresentativesByClubId(this.member.club_hash).then(response => {
        this.representativesList = response.data.data
      }).catch(error => {
        this.responseCatch(error)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

</style>
